/* google font */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;800&family=VT323&display=swap');

/* layout */
:root {
  --primary: #0FF4C6;
  --error: #e7195a;
}
body {
  background: #f1f1f1;
  margin: 0;
  font-family: "Poppins";
}
header {
  background: #DEFFF2;
}
header .container {
  max-width: 100%;
  margin: 0 auto;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header a {
  color: #464F51;
  text-decoration: none;
}
.pages{
  max-width: 1400px;
  padding: 20px;
  margin: 0 auto;
}

/* homepage */
.home {
  display: grid;
  /* grid-template-columns: 3fr 1fr; */
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* gap: 100px; */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product-details {
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
}
.product-details h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.product-details p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}
.product-details span {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
  background: #f1f1f1;
  padding: 6px;
  border-radius: 50%;
  color: #333;
}

.product-sections {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 20px;
}

/* new form */
label, input {
  display: block;
}
input {
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
}
form button {
  background: var(--primary);
  border: 0;
  color: #fff;
  padding: 8.5px;
  font-family: "Poppins";
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
}
div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}
input.error {
  border: 1px solid var(--error);
}

.supplier-form {
  align-self: flex-start;
  margin-bottom: 1rem;
  position: relative;
}

/* navbar */
nav {
  display: flex;
  align-items: center;
}
nav a {
  margin-left: 10px;
}
nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
  margin: 5px;
}

/* auth forms */
form.signup, form.login {
  max-width: 400px;
  margin: 40px auto;
  padding: 20px;
  background: #fff;
  border-radius: 4px;
}

.product-container {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 400px;
  flex-basis: calc(33.33% - 20px);
  margin: 5px;
}
.product-container button {
  background: #fff;
  color: var(--primary);
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
  padding: 10px;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 1em;
  margin-left: 10px;
  margin-bottom: 10px;
}
.product-container h4 {
  margin: 0 0 0px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.product-container p {
  margin: 0;
  font-size: 0.9em;
  /* color: #555; */
}

/* product-card */
.product-card {
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.05);
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  max-width: 400px;
  margin-bottom: 20px;
}

.product-card h4 {
  margin: 0 0 5px 0;
  font-size: 1.2em;
  color: var(--primary);
}

.product-card h3 {
  margin: 0 0 5px 0;
  font-size: 1em;
  color: var(--primary);
}

.product-card p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}

.product-card button {
  background: #fff;
  color: var(--primary);
  border: none;
  border-radius: 4px;
  font-family: "Poppins";
  cursor: pointer;
  font-size: 0.9em;
  margin-right: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: inline-flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.product-card.selected {
  background-color: rgb(222, 255, 242);
  border: none; /* Remove border */
}

/* product-container */
.product-container {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
}


/* cart */
.cart {
  /* max-width: 400px; */
  width: 100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  background: #fff;
  border-radius: 4px;
  margin: 20px auto;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
  /* min-width: 400px; */
}

.cart h4 {
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}

.cart p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}

.search-results-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  background: #fff;
  border-radius: 4px;
  padding: 10px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0,0,0,0.05);
  position: relative;
  width: 100%;
  max-width: 400px;
  margin: 0 auto; /* Center horizontally */
}

.search-results-container h3 {
  margin: 0 0 5px 0;
  font-size: 1em;
  color: var(--primary);
}

.search-results-container p {
  margin: 0;
  font-size: 0.9em;
  color: #555;
}

.input-button-container {
  display: flex;
  align-items: center;
}

.input-button-container input {
  flex: 1;
}

.input-button-container button {
  margin-left: 10px;
}

.mapContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1; 
}

.marker {
  display: block;
  border: none;
  cursor: pointer;
  padding: 0;
}
